<template>
  <div class="participation">
    <div class="bidd">
      <!-- 竞价销售我参与 -->
      <div class="biddings">
        <div
          class="item"
          v-for="(item, index) in readlist"
          :key="index"
          @click="ItemClick(item)"
        >
          <div class="item_title">
            <div class="item_goodsName">
              <el-tooltip effect="dark" placement="top-start">
                <div slot="content">
                  <span
                    v-for="(goodsName, index) in item.goodsCategoryList"
                    :key="index"
                    >{{ goodsName.goodsName }} &nbsp;
                    {{
                      index == item.goodsCategoryList.length - 1 ? "" : "|"
                    }}&nbsp;</span
                  >
                </div>
                <div>
                  <span
                    v-for="(goodsName, index) in item.goodsCategoryList"
                    :key="index"
                    >{{ goodsName.goodsName }} &nbsp;
                    {{
                      index == item.goodsCategoryList.length - 1 ? "" : "|"
                    }}&nbsp;</span
                  >
                </div>
              </el-tooltip>
              <div style="color: rgb(208, 17, 26)">
                竞价开始日期：{{ item.biddingStartTime }}
              </div>
              <div style="margin-left: 20px; color: rgb(208, 17, 26)">
                竞价结束日期：{{ item.biddingEtartTime }}
              </div>
            </div>
            <div style="display: flex">
              <div style="line-height: 40px; margin-right: 10px">
                {{ item.createTime }}
              </div>
              <!-- <div
                class="title_but"
                @click="closeDemand(item)"
                v-if="item.goodsStatus == 0"
              >
                关闭需求
              </div> -->
              <div class="title_but" v-if="item.goodsStatus == 0">未开始</div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 1"
                style="color: #139102"
              >
                进行中
              </div>
              <div
                class="title_but"
                v-if="item.goodsStatus == 2"
                style="color: #d0111a"
              >
                已流拍
              </div>
              <div class="title_but" v-if="item.goodsStatus == 3">拍卖成功</div>
              <div class="title_but" v-if="item.goodsStatus == 4">已关闭</div>
            </div>
          </div>
          <div class="item_name">
            <div class="item_images">
              <span v-if="item.taxIncluded == 0">含税</span>
              <span v-if="item.taxIncluded == 1">不含税</span>
              <span v-if="item.priceType == 0">未知</span>
              <span v-if="item.priceType == 1">坑口价</span>
              <span v-if="item.priceType == 2">出厂价</span>
              <span v-if="item.priceType == 3">船板价</span>
              <span v-if="item.priceType == 4">平仓价</span>
              <span v-if="item.priceType == 5">车板价</span>
              <span v-if="item.priceType == 6">到厂价</span>
            </div>
            <div style="margin-left: 20px" class="price-count">
              竞价底价：
              <el-tooltip effect="dark" placement="top-start">
                <div slot="content">
                  <span
                    v-for="(goodsItem, i) in item.goodsCategoryList"
                    :key="i"
                  >
                    <span>￥</span><span>{{ goodsItem.goodsPrice }}</span
                    >元/吨 &nbsp;
                    <span>数量</span>
                    <span>{{ goodsItem.number }}</span
                    >吨
                    <span v-if="i != item.goodsCategoryList.length - 1">|</span>
                  </span>
                </div>
                <span>
                  <span
                    v-for="(goodsItem, i) in item.goodsCategoryList"
                    :key="i"
                  >
                    <span style="color: #d0111a">￥</span
                    ><span style="color: #d0111a; font-size: 30px">{{
                      goodsItem.goodsPrice
                    }}</span
                    >元/吨 &nbsp;
                    <span>数量</span>
                    <span style="font-size: 30px; color: #d0111a">{{
                      goodsItem.number
                    }}</span
                    >吨
                    <span
                      v-if="i != item.goodsCategoryList.length - 1"
                      style="font-size: 26px"
                      >|</span
                    >
                  </span>
                </span>
              </el-tooltip>
            </div>
          </div>
          <!-- <div class="item_num">
            <div>
              数量：<span>{{ item.stockCount }}(吨)</span>
            </div>
            <div>竞价开始日期：{{ item.deliveryStartTime }}</div>
            <div>竞价结束日期：{{ item.deliveryEndTime }}</div>
          </div> -->

          <div class="companies">
            <div>
              <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
              <div class="place">产地：{{ item.originPlace }}</div>
            </div>

            <div class="company" v-if="item.goodsStatus == 1">
              <div class="company_item">
                <div class="title">
                  <i class="hy-icon-jiage"></i> 当前拍卖价
                </div>
                <div class="item_content">
                  <span style="color: rgb(208, 17, 26); margin-top: 10px"
                    >￥</span
                  >
                  <span class="item_num">{{
                    item.maxPrice == null||item.maxPrice === 0 ? "*" : item.maxPrice
                  }}</span>
                  <span style="margin-top: 10px">/吨</span>
                </div>
              </div>
              <div class="company_item">
                <div class="title">
                  <i class="hy-icon-jingpailiucheng-canyujingpai"></i> 起拍价
                </div>
                <div class="item_content">
                  <span style="color: rgb(208, 17, 26); margin-top: 10px"
                    >￥</span
                  >
                  <span class="item_num">{{ item.goodsCategoryList[0].goodsPrice }}</span>
                  <span style="margin-top: 10px"> /吨</span>
                </div>
              </div>
              <div
                class="company_item"
                style="margin: 0"
                v-if="item.endTime > 0"
              >
                <div class="title"><i class="hy-icon-shijian1"></i> 拍卖中</div>
                <div class="item_num">
                  <CountDown :endDate="`${item.endTime}`"></CountDown>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="allpage" v-if="readlist.length > 0">
          <el-pagination
            background
            @current-change="handleCurrentChange"
            :page-size="size"
            layout="prev, pager, next"
            :total="total"
          >
          </el-pagination>
        </div>
      </div>
      <!-- <div class="biddings">
       
        <div
          class="item"
          v-for="(item, index) in readlist"
          :key="index"
          @click="ItemClick(item.goodsId, item.goodsStatus,item.listType)"
        >
          <div class="item_title">
            <div class="item_time">
              <div>发布时间：{{ item.createTime }}</div>
              <div class="endtime">
                竞价结束日期时间：{{ item.baddingEtartTime }}
              </div>
            </div>

            <div class="title_but" v-if="item.goodsStatus == 0">未开始</div>
            <div class="title_but" v-if="item.goodsStatus == 1">进行中</div>
            <div class="title_but" v-if="item.goodsStatus == 2">已流拍</div>
            <div class="title_but" v-if="item.goodsStatus == 3">竞拍成功</div>
            <div class="title_but" v-if="item.goodsStatus == 4">已取消</div>
          </div>
          <div class="item_name">
            <div>
              商品名称：<span>{{ item.goodsName }}</span>
            </div>
            <div>
              竞价底价：<span>{{ item.price }}元/吨</span>
            </div>
            <div>
              <span v-if="item.taxIncluded == 0">含税</span>
              <span v-if="item.taxIncluded == 1">不含税</span>
              <span>出厂价</span>
            </div>
          </div>
          <div class="item_num">
            <div>
              数量：<span>{{ item.stockCount }}(吨)</span>
            </div>
          </div>
          <div class="item_btContent">
            <div class="content_pl">
              <div class="Delivery">交割地：{{ item.deliveryPlace }}</div>
              <div class="place">产地：{{ item.originPlace }}</div>
            </div>
            <div class="price">
              <div class="price_item">
                <div>当前最高价</div>
                <div>￥{{ item.maxPrice }}</div>
              </div>
              <div class="price_item">
                <div>起拍价</div>
                <div>￥{{ price }}</div>
              </div>
              <div class="price_item">
                <div>拍卖中</div>
                <div>{{ item.endTime }}</div>
              </div>
            </div>
          </div>
        </div>
        <div class="allpage" v-if="readlist.length > 0">
          <el-pagination
            background
            :total="total"
            :page-size="size"
            @current-change="handleCurrentChange"
            layout="prev, pager, next"
          >
          </el-pagination>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import { tradeGoods } from "@/api/public.js";
export default {
  components: {
    CountDown: () => import("@/components/countDown"),
  },
  data() {
    return {
      readlist: [],
      page: 1,
      size: 10,
      total: 0,
      timer: null,
      time: 0,
      systemTime: null,
    };
  },
  mounted() {
    this.getlist();
  },

  methods: {
    async handleCurrentChange(page) {
      let config = {
        page: page,
        size: this.size,
        selectOrderType: 1,
        selectTradeType: 2,
        selectType: 1,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        console.log(res);
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
          console.log(item);
        });
        this.total = res.data.total;
        this.readlist = list;
      }
    },
    toHHmmss(value) {
      var time;
      // var hours = parseInt((data % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
      // var minutes = parseInt((data % (1000 * 60 * 60)) / (1000 * 60));
      // var seconds = parseInt((data % (1000 * 60)) / 1000);
      let result = parseInt(value / 1000);
      let hours =
        Math.floor(result / 3600) < 10
          ? "0" + Math.floor(result / 3600)
          : Math.floor(result / 3600);
      let minutes =
        Math.floor((result / 60) % 60) < 10
          ? "0" + Math.floor((result / 60) % 60)
          : Math.floor((result / 60) % 60);
      let seconds =
        Math.floor(result % 60) < 10
          ? "0" + Math.floor(result % 60)
          : Math.floor(result % 60);
      time = hours + ":" + minutes + ":" + seconds;
      return time;
    },
    // Djs_time: function (val) {
    //   let val1 = +`${val}`;
    //   this.timer = setInterval(() => {
    //     let time = val1 - new Date().getTime();
    //     let h = Math.floor((time / 1000 / 60 / 60) % 24);
    //     let m = Math.floor((time / 1000 / 60) % 60);
    //     let s = Math.floor((time / 1000) % 60);
    //     let countDown = `${h} - ${m} - ${s}`;
    //     return countDown;
    //   }, 1000);
    // },
    async getlist() {
      let config = {
        page: this.page,
        size: this.size,
        selectOrderType: 1,
        selectTradeType: 2,
        selectType: 1,
      };
      let res = await tradeGoods(config);
      if (res.code == 0) {
        let newlist = res.data.records;
        let list = [];
        newlist.forEach((item) => {
          item.createTime = item.createTime.replace("T", " ");
          if (item.deliveryStartTime) {
            item.deliveryStartTime = item.deliveryStartTime.replace("T", " ");
          }
          if (item.deliveryEndTime) {
            item.deliveryEndTime = item.deliveryEndTime.replace("T", " ");
          }
          if (item.delFlag == 0) {
            list.push(item);
          }
        });
        this.total = res.data.total;
        this.readlist = list;
      }
    },
    ItemClick(item) {
      this.$router.push({
        path: "/details",
        query: {
          goodsId: item.goodsId,
          status: item.goodsStatus,
          listType: item.listType,
          selectTradeType: 2,
          selectOrderType: 1,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.companies {
  display: flex;
  justify-content: space-between;
}
.company {
  display: flex;
  // margin-right: 100px;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #e5e5e5;
  margin-bottom: 10px;
  .company_item {
    text-align: center;
    margin-right: 30px;
    .item_content {
      display: flex;
      align-items: center;
    }
    .item_num {
      font-size: 30px;
      line-height: 20px;
      color: rgb(208, 17, 26);
    }
    .title {
      font-size: 12px;
    }
  }
}
::v-deep .el-link--inner {
  font-size: 14px;
  line-height: 50px;
  height: 50px;
}
.participation {
  max-width: 1200px;
  margin: 20px auto 0;
}

.bidd {
  // width: 80%;
  // display: flex;
  // justify-content: space-between;
  background: white;
  padding: 10px;
  margin: 0 auto;
  .biddings {
    // width: 70%;
    .item {
      width: 100%;
      border: 1px solid #e5e5e5;
      padding: 10px;
      margin-top: 10px;
      .item_title {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px dotted #ccc;
        // padding-bottom: 10px;
        .item_goodsName {
          display: flex;
          line-height: 40px;
          :nth-child(1) {
            font-size: 18px;
            color: black;
            font-weight: bold;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 200px;
          }
          :nth-child(2) {
            margin-left: 13px;
          }
        }
        .title_but {
          padding: 10px;
          // border: 1px solid #ccc;
          cursor: pointer;
        }
      }
      .item_name {
        display: flex;
        // justify-content: space-between;
        margin-top: 5px;
        .item_images {
          width: 100px;
          height: 24px;
          background: url("../../assets/images/tag.png") no-repeat;
          background-size: 100% 100%;
          color: white;
          padding-left: 3px;
          padding-top: 2px;
          margin-top: 10px;
        }
        // :nth-child(1) {
        //   width: 300px;
        // }
        // :nth-child(2) {
        //   width: 300px;
        // }
      }
      .item_num {
        :nth-child(1) {
          // width: 300px;
        }
        // :nth-child(1) {
        //   width: 300px;
        // }
        :nth-child(2) {
          width: 300px;
        }
        display: flex;
        margin-top: 10px;
        // justify-content: space-between;
      }
      .Delivery {
        margin-top: 10px;
      }
      .place {
        margin-top: 10px;
      }
      .price-count {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        max-width: 500px;
      }
    }
  }
  .allpage {
    text-align: right;
    margin-top: 10px;
  }
  .release {
    height: 40px;
    padding: 10px 20px;
    border: 1px solid #ccc;
    cursor: pointer;
  }
}
// .bidd {
//   width: 80%;
//   display: flex;
//   justify-content: space-between;
//   margin: 0 auto;
//   .biddings {
//     width: 70%;
//     .item {
//       width: 100%;
//       border: 1px solid black;
//       padding: 10px;
//       margin-top: 10px;
//       .item_title {
//         display: flex;
//         justify-content: space-between;
//         border-bottom: 1px solid #ccc;
//         padding-bottom: 10px;
//         .item_time {
//           display: flex;
//           .endtime {
//             margin-left: 10px;
//           }
//         }
//         .title_but {
//           padding: 10px;
//           border: 1px solid #ccc;
//           cursor: pointer;
//         }
//       }
//       .item_name {
//         display: flex;
//         margin-top: 5px;
//         :nth-child(1) {
//           width: 300px;
//         }
//         :nth-child(2) {
//           width: 300px;
//         }
//       }
//       .item_num {
//         :nth-child(1) {
//           width: 300px;
//         }
//         :nth-child(2) {
//           width: 300px;
//         }
//         display: flex;
//         margin-top: 10px;
//       }
//       .item_btContent {
//         display: flex;
//         .content_pl {
//           .Delivery {
//             margin-top: 10px;
//           }
//           .place {
//             margin-top: 10px;
//           }
//         }
//         .price {
//           display: flex;
//           text-align: center;
//           margin-left: 60px;
//           font-size: 20px;
//           .price_item {
//             margin-left: 20px;
//             :nth-child(2) {
//               margin-top: 10px;
//             }
//           }
//         }
//       }
//     }
//   }
//   .allpage {
//     text-align: right;
//     margin-top: 10px;
//   }
//   .release {
//     height: 40px;
//     padding: 10px 20px;
//     border: 1px solid #ccc;
//     cursor: pointer;
//   }
// }
</style>
